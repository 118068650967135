<template>
  <div class="about">

      <!--顶部-->
      <headbox></headbox>

      <!-- 滑块组件 -->
    <banner positionSid="6433"
            showTitle="true"
            :title="this.$store.state.project.projectName"
            :englishName="this.$store.state.project.projectEnglishName"
            :content="this.$store.state.project.projectDesc">

    </banner>

    <!--关于我们-->
    <gywm :titleEnglish="this.$store.state.project.titleEnglishIsShow" bjImgHidden="1"></gywm>

    <!--公司理念-->
    <gsln :titleEnglish="this.$store.state.project.titleEnglishIsShow" bjImgHidden="1"></gsln>

      <!-- 侧边导航 -->
      <sidebar ref="mysiderbar"></sidebar>

      <!--底部-->
      <foot></foot>

  </div>
</template>

<script>
import Swiper from 'swiper'
import headbox from "@/components/head";//
import gsln from "@/components/gsln";
import foot from "@/components/foot";
import sidebar from "@/components/sidebar";
import banner from "@/components/banner";
import gywm from "@/components/gywm.vue";

export default {
    components: {
      gywm,
      banner,
      headbox,
      foot,
      gsln,
      sidebar,
    },
    data() {
        return {
        }
        },
    methods:
        {
            hideChat(e){
                this.$refs.mysiderbar.hideChat()
            },
        },

  mounted(){
    var swiper = new Swiper('.swiper-container', {
        direction: 'vertical',
        // slidesPerView: 1,
        // spaceBetween: 30,
        mousewheel: true,
        mousewheelControl : true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });
      // 页面增加全局点击
      window.addEventListener('click', this.hideChat)
  },
    beforeDestroy() {
        /* 组件销毁的时候要移除侦听器 */
        window.removeEventListener('click', this.hideChat)
    },
    beforeMount() {
    },
}
</script>

<style scoped>
    .about{
        width: 100%;
        overflow-x: hidden;
      font-size: 12px;
    }
</style>
